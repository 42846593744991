import * as Sentry from '@sentry/browser';
import cls from 'classnames';
import { compareDesc, parse } from 'date-fns';
import debounce from 'lodash.debounce';
import { useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import type { ShortInboxMessage } from '@/types/messages';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';

import Loader from '@/components/Loader';
import { useClearInbox } from '@/hooks/useClearInbox';
import useInboxStream from '@/hooks/useInboxStream';
import { getMessages } from '@/services/api/inbox';

import styles from './Inbox.module.scss';

import CoffeeIcon from '@/images/coffee.svg?react';
import ErrorIcon from '@/images/error-triangle.svg?react';
import SearchIcon from '@/images/icons/search.svg?react';

interface Column {
  name: string;
  getValue: (props: ShortInboxMessage) => string | number;
  styles?: string;
}

interface Cell {
  id: string;
  value: string | number;
  styles?: string;
}

interface Row extends ShortInboxMessage {
  id: string;
  cells: Cell[];
}

const columns: Column[] = [
  {
    name: 'From name',
    getValue: (props) => props.from[0].name || '-',
    styles: styles.listCellEmail,
  },
  {
    name: 'From e-mail',
    getValue: (props) => props.from[0].address || '-',
    styles: styles.listCellEmail,
  },
  {
    name: 'Subject',
    getValue: (props) => props?.subject,
    styles: styles.listCellSubject,
  },
  {
    name: 'Received',
    getValue: (props) => props?.received,
    styles: styles.listCellReceived,
  },
];

const SEARCH_DEBOUNCE_TIME = 200;
const REFRESH_INTERVAL = 5000;

const parseDate = (received: string) => parse(received, 'MM/dd/yyyy, h:mm:ss a', new Date());

const Inbox = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = (searchParams.get('query') ?? '').toLowerCase();
  const shouldFirstDeleteInbox = !!(
    searchParams.has('clear') && searchParams.get('clear') !== 'false'
  );
  const navigate = useNavigate();
  const { messages, setMessages } = useOutletContext<{
    messages: ShortInboxMessage[];
    setMessages: Dispatch<SetStateAction<ShortInboxMessage[]>>;
  }>();
  const refetchTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { name = '' } = useParams();
  const clearInbox = useClearInbox();

  const fetchMessages = useCallback(async () => {
    try {
      const response = await getMessages(name);
      if (!response.ok) {
        Sentry.captureMessage(`${response.statusText} (${response.status})`);
      } else {
        const messageList: ShortInboxMessage[] = await response.json();
        setMessages((prev: ShortInboxMessage[]) =>
          messageList.map((message) => {
            prev.find(({ messageId }) => messageId === message.messageId);
            const index = prev.findIndex(({ messageId }) => messageId === message.messageId);
            return index !== -1 ? prev[index] : message;
          }),
        );
      }
    } catch {
      Sentry.captureMessage('Failed to fetch messages', 'info');
    }
  }, [name, setMessages]);

  const clearAllMessages = useCallback(
    async (...params: Parameters<typeof clearInbox>) => {
      try {
        await clearInbox(...params);
        setMessages([]);
        navigate(window.location.pathname, { replace: true });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [clearInbox, navigate, setMessages],
  );

  const handleMessagesUpdate = useCallback(
    (messagesUpdate: ShortInboxMessage[]) => {
      setMessages(messagesUpdate);
      if (refetchTimeout.current) {
        clearTimeout(refetchTimeout.current);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setMessages],
  );

  const { isLoading, hasUnexpectedServerError } = useInboxStream(handleMessagesUpdate);

  const initialFetch = useCallback(async () => {
    if (shouldFirstDeleteInbox) {
      await clearAllMessages({ trigger: 'url', name });
    }
    await fetchMessages();
  }, [shouldFirstDeleteInbox, fetchMessages, clearAllMessages, name]);

  useMount(() => {
    refetchTimeout.current = setTimeout(() => fetchMessages(), REFRESH_INTERVAL);
    initialFetch();
    return () => handleMessagesUpdate([]);
  });

  const processedMessages = useMemo(() => {
    const sortedMessages = messages.sort((a, b) =>
      compareDesc(parseDate(a.received), parseDate(b.received)),
    );

    const result: { unseen: number; headers: Column[]; rows: Row[] } = {
      unseen: 0,
      headers: columns,
      rows: [],
    };
    const filteredMessages = !query
      ? sortedMessages
      : sortedMessages.filter(
          (message) =>
            message.subject.toLowerCase().includes(query) ||
            message.html?.toLowerCase().includes(query),
        );

    if (filteredMessages.length) {
      result.unseen = filteredMessages.filter(({ unseen }) => unseen).length;
      result.rows = filteredMessages.map((message: ShortInboxMessage) => {
        const cells = columns.map((column) => ({
          id: column.name,
          value: column.getValue(message),
          ...column,
        }));
        return {
          ...message,
          id: message.messageId,
          cells,
        };
      });
    }
    return result;
  }, [messages, query]);

  const handleSearch = useMemo(
    () =>
      debounce(
        (event: ChangeEvent<HTMLInputElement>) => setSearchParams({ query: event.target.value }),
        SEARCH_DEBOUNCE_TIME,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderContent = () => {
    if (hasUnexpectedServerError) {
      return (
        <div className={styles.errorState}>
          <ErrorIcon />
          Something went wrong.
          <br />
          Try to refresh the page after short while.
        </div>
      );
    }

    if (!messages.length) {
      return (
        <div className={styles.emptyState}>
          <CoffeeIcon />
          <span className={styles.emptyStateLoader}>
            New messages will appear automatically as they arrive
          </span>
        </div>
      );
    }

    return processedMessages.rows.map((row) => (
      <Link
        key={row.id}
        className={cls(styles.listRow, { [styles.listRowUnseen]: row.unseen })}
        to={`/${name}/messages/${row.id}/`}
      >
        {row.cells.map((cell) => (
          <div key={cell.id} className={cls(styles.listCell, cell.styles)}>
            <span>{cell.value}</span>
          </div>
        ))}
      </Link>
    ));
  };

  return (
    <>
      <Helmet titleTemplate="Bugbug Inbox / %s">
        <title>{name}</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            Inbox
            {processedMessages.unseen ? <span>{processedMessages.unseen}</span> : null}
          </h1>

          <div className={styles.search}>
            <SearchIcon />
            <input placeholder="Search..." defaultValue={query} onChange={handleSearch} />
          </div>
        </div>
        <div role="table" className={styles.list}>
          <div className={styles.listHeader}>
            <div className={styles.listRow}>
              {processedMessages.headers.map((header) => (
                <div key={header.name} className={cls(styles.listCell, header.styles)}>
                  {header.name}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.listBody}>
            {isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader />
                Loading messages
              </div>
            ) : (
              renderContent()
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Inbox;
